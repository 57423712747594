<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>设备上报2</span>
      </div>
      <div v-if="tableData.length>0">
        <div class="sub-equipment-model">
          <div class="equipment-item" v-for="(item, index) in tableData" :key="index">
            <div class="equipment-header">
              <div class="h-name">
                <i class="iconfont icon-wangguanshebei"></i>    
                <span style="margin-left:10px;">
                  {{item.waterworks?item.waterworks + item.position:'-'}}
                </span>
              </div>
              <div class="h-time">
                <el-button size="mini" type="primary" @click="record(item)">历史记录</el-button>
              </div>
            </div>
            <div class="equipment-middle">
              <div class="middle-params">
                <div>设备名称</div>
                <div>{{item.subEquipmentName?item.subEquipmentName:'-'}}</div>
              </div>
              <div class="middle-params">
                <div>设备号</div>
                <div>{{item.eid?item.eid:'-'}}</div>
              </div>
              <div class="middle-params">
                <div>通讯地址</div>
                <div>{{item.content.equipmentNumber?item.content.equipmentNumber:'-'}}</div>
              </div>
              <div class="middle-params">
                <div>瞬时流量</div>
                <div>{{item.content.flowInstantaneous?item.content.flowInstantaneous:0}}</div>
              </div>
              <div class="middle-params">
                <div>瞬时流速</div>
                <div>{{item.content.fluidVelocity?item.content.fluidVelocity:0}}</div>
              </div>
              <div class="middle-params">
                <div>正累积流量</div>
                <div>{{item.content.positiveCumulativeFlow?item.content.positiveCumulativeFlow:0}}</div>
              </div>
              <div class="middle-params">
                <div>负累积流量</div>
                <div>{{item.content.negativeCumulativeFlow?item.content.negativeCumulativeFlow:0}}</div>
              </div>
              <div class="middle-params">
                <div>上报时间</div>
                <div>{{ item.reportTime ? dayjs(item.reportTime).format('YYYY-MM-DD HH:mm:ss'):'-' }}</div>
              </div>
              <!-- <div class="middle-params">
                <div>备注</div>
                <div>{{item.remarks?item.remarks:'-'}}</div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="block">
          <el-pagination
            @size-change="gatewaySizeChange"
            @current-change="gatewayPageChange"
            :current-page="gatewayPage"
            :page-size="gatewaySize"
            :page-sizes="[8]"
            background
            :layout="layout"
            :total="gatewayTotal"
          ></el-pagination>
          <!-- :pager-count="count" -->
        </div>
      </div>
      <div v-else style="text-align:center;margin-top:20px;">暂无数据</div>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,

      gatewaySize: 8,
      gatewayPage: 1,
      gatewayTotal: 0,

      formInline: {},
      tableData: [],
      roleId: 0,
      timer: null
    };
  },
  computed: {
    layout() {
      let str = "total, sizes, prev, pager, next, jumper";
      this.isSizes?str="sizes,"+str:"";
      return str;
    }
  },
  methods: {
    dayjs,
    details(row) {
      this.$router.push({ name: 'deviceListDetail', params: {row: row}})
    },
    dlt(row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$ajax.post('deviceDelete', {
          id: row.id
        }).then(res => {
          this.loadListData()
        })
      })
    },
    // 记录
    record(row) {
      this.$router.push({ name: 'iotReportSecondRecord', params: {row: row}})
    },
    // 获取设备
    loadListData() {
      this.loading = true
      this.$ajax.post('deviceReport',{
        pageNum: this.gatewayPage,
        pageSize: this.gatewaySize
      }).then(res => {
        this.tableData = res.data
        this.gatewayTotal = res.total
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    // 网关
    gatewayPageChange(val) {
      this.gatewayPage = val
      sessionStorage.setItem('currentPage', val)
      this.loadListData()
    },
    gatewaySizeChange(val) {
      this.gatewaySize = val
      sessionStorage.setItem('currentSize', val)
      this.loadListData()
    },
    getLastPage() {
      this.gatewayPage = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.gatewaySize = Number(sessionStorage.getItem('currentSize')) || 8
    },
  },
  mounted() {
    this.getLastPage()
    this.getSize()
    this.loadListData()
    this.timer = setInterval(()=>{
      this.loadListData()
    },60000)
    this.roleId = sessionStorage.getItem('r')
  },
  destroyed() {
    // sessionStorage.removeItem('currentPage')
    // sessionStorage.removeItem('currentSize')
    clearInterval(this.timer)
  }
};
</script>

<style lang="less" scoped>
.block /deep/{
  /* text-align: center;
    background: #fff;
    margin-top: 10px; */
  /* border: 1px solid #ebeef5 */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  padding: 0 20px;
  .el-pager li {
    margin: 0 5px;
    border-radius: 2px;
  }
}
.el-dropdown-dis {
  color: #ccc;
  cursor: not-allowed;
}
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.sub-equipment-model {
    // background: #f0f0f0;
    padding: 0 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.equipment-item {
    width: 21.4%;
    // height: 220px;
    // background: #F0F3F8;//F0F3F8
    box-shadow: 0px 0px 20px rgba(211, 211, 211, .5);
    border: 1px solid #ebeef5;
    border-radius: 15px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.equipment-item:nth-child(4n) {
    margin-right: 0px;
}
.equipment-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // height: 30px;
    margin-bottom: 10px;
    font-size: 16px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    .h-time {
        color: #aaa;
        font-size: 14px;
    }
}
.equipment-middle {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
} 
.middle-params {
    width: 50%;
    padding: 7px 0;
    color: #333;
    font-size: 15px;
    border-radius: 10px;
    text-align: center;
    transition: all .3s ease;
}
.middle-params:hover {
    background: #F3F7FB;
    // background: #d8dbe0;
}
.middle-params div:nth-child(1) {
    color: #999;
    font-size: 13px;
    margin-bottom: 5px;
}
</style>